import React, { useEffect } from "react";
import Footer from "../components/Footer";
function Terms() {
    useEffect(() => { document.title = "Terms and Policies | Zero Drop Taxi";
                    }, []);
    return (
        <div style={{paddingTop:"120px"}}>
            <h1 style={{textAlign:'center', fontSize:'3em'}}>Terms of Use and Policies</h1>
            <br/>
            <br/>
            <div style={{
                width: "80%",
                fontSize: "13px",
                margin: "0 auto ",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                overflowY: "auto",
                // overflowX:"hidden",
                height: "60vh",
            }}>


                <div
                    style={{
                        overflowY: "auto",
                        height: "50vh",
                        flex: "1 1 100%",
                        padding: "20px",
                        textWrap: "wrap",

                    }}
                >
                    <p>
                        The mobile Platform <em>Zero Drop Taxi </em>(the “<strong>Application</strong>”)
                        and the website <a href="http://www.zerodroptaxi.com">www.zerodroptaxi.com</a> (the “
                        <strong>Website</strong>”) (collectively the “<strong>Platform</strong>”) is
                        owned and operated by Fluezen technology Private Limited, a company
                        incorporated in India, having its registered office at No 55,<sup>nd</sup>{" "}
                         Devi Karumaiamman Street, Kodungaiyur Chennai - 600073 (the “<strong>Company</strong>”,
                        which expression would mean and include its officers, successors and
                        permitted assigns).&nbsp; The Company is in the business of providing
                        inter-city taxi services. Such online services made available through the
                        Platform are hereinafter referred to as the “<strong>Services</strong>
                        ”.&nbsp;
                    </p>
                    <p>
                        This document is an electronic record in terms of the applicable law. This
                        electronic record is generated by a computer system and does not require any
                        physical or digital signatures.
                    </p>
                    <p>
                        For the purpose of these Terms of Use, wherever the context so requires,
                        <strong>&nbsp;</strong>“<strong>you</strong>”,&nbsp;“<strong>your</strong>”
                        or “<strong>user</strong>”, as applicable,&nbsp;shall mean any natural or
                        legal person who accesses and/or uses the Platform and/or transacts business
                        through the Platform by providing registration information. The terms&nbsp;“
                        <strong>we</strong>”,<strong>&nbsp;</strong>“<strong>us</strong>”,
                        <strong>&nbsp;</strong>“<strong>our</strong>”&nbsp;shall mean the Company.
                    </p>
                    <p>
                        <strong>
                            <u>Please Read These Terms Of Use Carefully Before Using This Platform</u>
                        </strong>
                    </p>
                    <ol>
                        <li>
                            <strong>Applicability And Amendment Of Terms</strong>
                            <ol>
                                <li>
                                    These Terms of Use and the privacy policy available at{" "}
                                    <u>https://www.ZeroDropTaxi.com/</u> (“
                                    <strong>Privacy Policy</strong>”) are applicable to the entire content
                                    of the Platform and the Services. We request you to carefully go
                                    through these Terms of Use and the Privacy Policy before you decide to
                                    access this Platform or use the Services.
                                </li>
                                <li>
                                    These Terms of Use and the Privacy Policy apply to all visitors and
                                    users of the Platform and the Services and together constitute a legal
                                    agreement (“<strong>Agreement</strong>”) between you and the Company
                                    in connection with your visit to the Platform and your use of the
                                    Services.
                                </li>
                                <li>
                                    Your use of the Platform or the Services will signify your acceptance
                                    of the Agreement and your agreement to be legally bound by the same.
                                    If you do not agree to or wish to be bound by the terms of the
                                    Agreement, you may not access or otherwise use the Platform or the
                                    Services.
                                </li>
                                <li>
                                    We reserve the right to modify or terminate any portion of the
                                    Platform or the Services offered by the Company or amend the Terms of
                                    Use as well as the Privacy Policy for any reason, without notice and
                                    without liability to you or any third party. To make sure you are
                                    aware of any changes, please review these Terms of Use periodically.
                                    The Company may require you to provide your consent to the updated
                                    Terms of Use in a specified manner before any further use of the
                                    Platform and the Services. If no such separate consent is sought, your
                                    continued use of the Platform will constitute your acceptance of such
                                    changes.
                                </li>
                                <li>
                                    Nothing in the Agreement should be construed to confer any rights to
                                    third party beneficiaries.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Your Account And Registration</strong>
                            <ol>
                                <li>
                                    You will be required to register on the Platform and create an account
                                    prior to the completion of any transaction / purchase through the
                                    Platform. We reserve the right to decline a registration or cancel an
                                    account at any time without assigning any reason to you.You may also
                                    book through the phone or make a booking without registering on the
                                    Platform, by providing your contact details to our customer care. The
                                    said terms and conditions will be applicable for those bookings as
                                    well and you expressly agree to comply with these Terms of Use in case
                                    of telephonic bookings.
                                </li>
                                <li>
                                    Use of the Platform is available only to persons who can form legally
                                    binding&nbsp;contracts under the applicable law. Persons who are
                                    underage or otherwise "incompetent to contract" within the meaning of
                                    the applicable laws are not eligible to use the Platform unless such
                                    persons are acting under the guidance of a person legally entitled to
                                    contract. If you not entitled to contract under the applicable law,
                                    you shall not register as a user of the Platform and shall not
                                    transact on or use the Platform. The Company reserves the right to
                                    terminate&nbsp;your membership and / or refuse to provide you with
                                    access to the Platform if you contravene these Terms or any other
                                    policies introduced by the Company.We may require users to re-validate
                                    their account details if we believe that they have been using an
                                    invalid email address.
                                </li>
                                <li>
                                    To register onto the Platform, you will have to provide your Personal
                                    Information (as defined in the Privacy Policy). To log in to your
                                    account, you will have to enter your registered mobile number, email
                                    address and the one-time password sent to your registered mobile
                                    number by the Company. Alternatively, you may use the login
                                    credentials for the account maintained by you with certain identified
                                    third-party sites that we support (illustratively, Google or Facebook)
                                    (“<strong>Third Party Sites</strong>”). You understand and agree that
                                    by using the login credentials for the account maintained by you with
                                    Third Party Sites, you will also be subject to specific terms and
                                    conditions applicable to your account maintained with such third-party
                                    sites (“<strong>Third Party Terms</strong>”).We assume no
                                    responsibility for Third Party Sites and we accept no responsibility
                                    for any loss or damage that may arise from your use of them.
                                </li>
                                <li>
                                    We may send you administrative and promotional emails. We may also
                                    send you information regarding your account activity and purchases, as
                                    well as updated information about thePlatform and other promotional
                                    offers. You can opt-out of our promotional emails at any time by
                                    clicking the unsubscribe link at the bottom of any such email
                                    correspondence.
                                </li>
                                <li>
                                    Registration is only a one-time process and if you have previously
                                    registered, you may login /sign in to your registered account using
                                    the same credentials as provided by you during the registration
                                    process.
                                </li>
                                <li>
                                    Membership is free for all users. However, a fee may be charged for
                                    subscribing to any new/additional services offered by the Company
                                    exclusively to members. The Company reserves the right to change the
                                    fee policy applicable to such paid membership. Changes to the fee
                                    policy will be posted on the Platform and such changes shall become
                                    effective immediately after they are posted on the Platform.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>BOOKING PROCESS</strong>
                            <ol>
                                <li>
                                    A booking ID No (confirmation) will be sent to the traveler over email
                                    when he completes an online car booking with Zero Drop Taxi along with a SMS
                                    update on the same. Producing the booking ID No. provided earlier is
                                    mandatory to avail the car rental services by Zero Drop Taxi. If this is not
                                    done, it entitles the driver to refuse the Services without any
                                    liability to us.
                                </li>
                                <li>
                                    When a customer produces the booking ID No provided to him it earlier,
                                    it implies that he have read and understood all terms and conditions
                                    mentioned herewith and agrees on all of them.
                                </li>
                                <li>
                                    The start and end distance in Kms will be calculated from your pickup
                                    place. Driving speed of the vehicle will be as per the directions of
                                    the local transport authority only. In the event, you ask the driver
                                    to increase the driving speed, you alone shall be responsible for all
                                    consequence, statutory or otherwise. Any parking charges, toll
                                    charges, state taxes and entry fees etc. are required to be paid by
                                    you.
                                </li>
                                <li>
                                    Distances between two locations (points) be it an intercity distance
                                    or from travelers pickup place etc mentioned on the website are
                                    approximate. Actual distance may differ depending on the exact
                                    geographical location of the points under consideration.
                                </li>
                                <li>
                                    The facilities in the car will be dependent on the booking made by
                                    you. Car air-conditioner will not work on hilly routes &amp; also when
                                    the vehicle is not in motion. The customer is also not expected to
                                    request driver to make the air condition operational in the
                                    aforementioned cases.
                                </li>
                                <li>
                                    Any form of discounts are only applicable only on the basic fare and
                                    cannot be made available on extra charges like toll, taxes, charges on
                                    for extra Kms consumed if applicable, charges for extra hours consumed
                                    if applicable, driver allowance, parking, night charges, unless
                                    specified.
                                </li>
                                <li>
                                    The vehicle booked will be subject to availability. In a case where
                                    the car booked is not available on the day of travel the customer will
                                    be provided with an alternative equivalent vehicle in the similar
                                    segment as applicable.
                                </li>
                                <li>
                                    You can request for amendments to a booking made earlier only if the
                                    request is done 10 hours before the pick-up time. No changes will be
                                    done within 10 hours of the time of pick up time. In cases where
                                    amendments are requested on cab type, the cab type requested is
                                    subject to availability. In case of a no show where the customer has
                                    not informed Zero Drop Taxi on the changes on his schedule 10 hours before
                                    the pick-up no refunds will be made. The amendments can be done by
                                    contacting the support team of Zero Drop Taxi on +91 7385380026 and mention
                                    the booking ID No for references.
                                </li>
                                <li>
                                    You are liable to pay waiting charges in the case of delay (be it for
                                    any reason) exceeding more than 15 minutes from the scheduled pick up
                                    time. You are liable to pay for any additional distance travelled,
                                    additional unaccounted stops made and also for additional hourly
                                    charges, if applicable. Any unscheduled changes will attract
                                    additional charges as applicable.
                                </li>
                                <li>
                                    All bookings under consideration are on a calendar day basis i.e from
                                    midnight 12:00 to midnight 12:00. As an example for a cab booked for
                                    14th Apr where the pick-up time is 3:00 p.m, the booking will be valid
                                    till 14th Apr 11:59 p.m and not 15th Apr 3:00 p.m.
                                </li>
                                <li>
                                    Cabs that are booked for outstation (any or all of Oneway, round trip
                                    &amp; multicity) cannot be used for local travel. As an example if a
                                    customer books a cab from Chennai to Bangalore then to Nandi Hills and
                                    back to Chennai for a period of 3 days in total &amp; has a predefined
                                    Km limit of 800 Kms and he returns back to Chennai on the second day
                                    &amp; has a balance Km limit of 50 with one day remaining, inspite of
                                    this he is not entitled to consume this 50Kms for local travel in
                                    Chennai (From city), however the customer can travel from Chennai to
                                    some other city outside city limits of Chennai (say Chengalpet) &amp;
                                    consume the remaining 50 Kms or more than this by paying additional
                                    charges.
                                </li>
                                <li>
                                    If needed, a customer can amend his booking from one way to round trip
                                    or from one way to multi city while his travel is in progress. A
                                    request for amendment while mentioning the booking ID number &amp;
                                    reason for amendment has to be made to the support team at Zero Drop Taxi by
                                    reaching them on +91 73583 80026, Whether or not to make an amendment as
                                    per the request of the customer solely rests with our support team. If
                                    the amendment request by the customer is approved our support team
                                    they will guide on the difference amount to be paid to the driver as
                                    to make the amendment a reality. This amount has to be paid
                                    immediately to the driver in cash after our team makes an amendment,
                                    failure to do so will not make the requested amendment a reality.
                                </li>
                                <li>
                                    For all travel types (one way, round trip) customer has to bear all
                                    extra charges like driver allowance, toll, taxes, charges on for extra
                                    Kms consumed if applicable, charges for extra hours consumed if
                                    applicable, parking charges, night charges and any other charges
                                    applicable as mentioned at the time of booking.
                                </li>
                                <li>
                                    <strong>
                                        <u>Luggage Policy</u>
                                    </strong>
                                    :&nbsp;The following is the maximum limit of luggage which you are
                                    permitted to carry in a taxi booked through Zero Drop Taxi. Any deviation
                                    from this policy shall result in additional payments being made by you
                                    to our driver.
                                    <table id="lp" width={556}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={4} width={556}>
                                                <p>
                                                    <strong>Luggage Policy</strong>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={163}>
                                                <p>Type of Vehicle</p>
                                            </td>
                                            <td colSpan={2} width={161}>
                                                <p>Persons</p>
                                            </td>
                                            <td width={233}>
                                                <p>Pieces of Luggage</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={163}>
                                                <p>&nbsp;</p>
                                            </td>
                                            <td width={80}>
                                                <p>Comfort</p>
                                            </td>
                                            <td width={81}>
                                                <p>Squeeze</p>
                                            </td>
                                            <td width={233}>
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={163}>
                                                <p>
                                                    <strong>Hatch Backs</strong>&nbsp; (Indica Ritz Beat etc)
                                                </p>
                                            </td>
                                            <td width={80}>
                                                <p>3</p>
                                            </td>
                                            <td width={81}>
                                                <p>4</p>
                                            </td>
                                            <td width={233}>
                                                <p>2 suitcases of 10 Kg each</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={163}>
                                                <p>
                                                    <strong>Sedans</strong> (Dzire Etios Indigo etc)
                                                </p>
                                            </td>
                                            <td width={80}>
                                                <p>3</p>
                                            </td>
                                            <td width={81}>
                                                <p>4</p>
                                            </td>
                                            <td width={233}>
                                                <p>3 suitcases of 10 Kg each</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={163}>
                                                <p>
                                                    <strong>MUVs</strong>&nbsp; (Xylo Innova Enjoy etc)
                                                </p>
                                            </td>
                                            <td width={80}>
                                                <p>6</p>
                                            </td>
                                            <td width={81}>
                                                <p>7</p>
                                            </td>
                                            <td width={233}>
                                                <p>6 suitcases of 10 Kg each</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} width={556}>
                                                <p>
                                                    We understand luggages are not only suitcases. They are of
                                                    different shapes and sizes. Above table is only indicative.
                                                    Excess luggage charges, if any, will be at the discretion of
                                                    driver. You can call our Call centre for any dispute
                                                    resolution
                                                </p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    For one way oustation trips the customer will pay for any additional
                                    charges in the case where the driver needs to cover additional
                                    distance (or) needs additional duration to complete his return journey
                                    (whichever of these two higher).
                                </li>
                                <li>
                                    Zero Drop Taxi cannot be held liable for any (i) delay or consequential loss
                                    caused to the customer due to traffic jam or reasons beyond reasonable
                                    control of the driver or lapses on the part of the driver; (ii) loss,
                                    damage, physical injury etc suffered by the customer while availing
                                    the Services; (iii) any misconduct or any derogatory behavior of the
                                    driver; (iv) Any dispute between you and the driver; and (v) force
                                    majeure events.. In case of any misconduct or misbehavior by the
                                    driver the customer should immediately report to the support team of
                                    Zero Drop Taxi at +91 73583 80026 and share the experience while mentioning
                                    the booking ID No for reference. While we will attempt to resolve any
                                    dispute, we cannot guarantee that we will remedy any losses incurred
                                    or suffered by you. As all drivers listed with us have produced a
                                    police verification letter attested by the local station office (in
                                    case of the officer refusing to attest this letter, the same has been
                                    stated and signed by the driver). This is the maximum due diligence
                                    practically and logically possible for us to have adhered to, to
                                    verify the criminal and traffic violation records of drivers, given we
                                    are only an aggregating platform and as of now no driver is on our pay
                                    rolls
                                </li>
                                <li>
                                    In a scenario, where in a customer already has received the driver
                                    &amp; cab details and the cab hired does not show up on the day of
                                    travel, it will be considered as the failure of the fleet partner of
                                    Zero Drop Taxi. Zero Drop Taxi being a Taxi Booking Facilitator will take all
                                    necessary efforts to provide the customer an alternative mode of
                                    transport immediately, in the shortest possible time. Zero Drop Taxi is not
                                    liable to reimburse its customer for any expenses like hotel stay
                                    charges, flight ticket charges or any other type of monetary expenses
                                    which the customer had to bear as a result of no show of the cab on
                                    the day of planned cab travel.
                                </li>
                                <li>
                                    You acknowledge that each car booked by availing the Services can be
                                    used by limited number of passengers only. In the case of outstation
                                    travel, you are obligated to inform Zero Drop Taxi of the number of persons
                                    travelling in one car and Zero Drop Taxi reserves the right to accept or
                                    refuse to provide Services. In any event, Zero Drop Taxi reserves the right
                                    to refuse Services if you attempt to use the car for members exceeding
                                    the designated number of members for such car.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Access</strong>
                            <ol>
                                <li>
                                    You are responsible for maintaining the confidentiality of your
                                    account and password and for restricting and preventing unauthorized
                                    access to your account. You agree to accept responsibility for all
                                    activities that occur under your account or password.
                                </li>
                                <li>
                                    You agree to (a) immediately notify the Company of any unauthorized
                                    use of your account information or any other breach of security, and
                                    (b) ensure that you exit from your account at the end of each session.
                                    The Company cannot and will not be liable for any loss or damage
                                    arising from your failure to comply with these conditions. You may be
                                    held liable for losses incurred by the Company or any other user of or
                                    visitor to the Platform due to authorized or unauthorized use of your
                                    account as a result of your failure in keeping your account
                                    information secure and confidential.
                                </li>
                                <li>
                                    You can access account details on the Platform. You shall ensure that
                                    the account details provided to us through the Platform are correct
                                    and complete at all times. We reserve the right to refuse your access
                                    to the Platform, terminate accounts, remove or edit content at any
                                    time without notice to you.
                                </li>
                                <li>
                                    The Company will endeavor to ensure that access to and availability of
                                    the Platform remains uninterrupted and error free. However, access to
                                    the Platform may occasionally be suspended or restricted to allow for
                                    updates, repairs, maintenance, or for the introduction of new
                                    facilities and services. We also reserve the right to block access to
                                    and/or to edit or remove any material posted by you or any other user
                                    which in our reasonable opinion may give rise to a breach of this
                                    Agreement.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Limited License For Access</strong>
                            <ol>
                                <li>
                                    The Company grants you a limited license to access and make personal
                                    and non-commercial use of this Platform, but not to modify it (or any
                                    portion thereof), except with prior written consent of the Company and
                                    / or its affiliates, as may be applicable.
                                </li>
                                <li>
                                    This license does not include any resale or commercial use of this
                                    Platform or its contents;
                                </li>
                                <li>
                                    This Platform or any portion thereof (including but not limited to any
                                    copyrighted material, trademarks, or other proprietary information)
                                    may not be reproduced, duplicated, copied, sold, resold, visited,
                                    distributed or otherwise exploited for any commercial purpose without
                                    prior written consent of the Company and / or its affiliates, as may
                                    be applicable.
                                </li>
                                <li>
                                    You will not frame or use framing techniques to enclose any trademark,
                                    logo, or other proprietary information (including images, text, page
                                    layout, or form) of the Company and its affiliates without prior
                                    written consent. You will not use any meta tags or any other "hidden
                                    text" utilizing the Company’s or its affiliates' names or trademarks
                                    without the prior written consent of the Company and / or its
                                    affiliates, as may be applicable. Any unauthorized use will terminate
                                    the permission or license granted by the Company and / or its
                                    affiliates, as may be applicable.
                                </li>
                                <li>
                                    You are entitled to post reviews, ratings, communication and other
                                    content on the Platform (collectively “<strong>User Content</strong>
                                    ”). In contributing any User Content, you represent that you are the
                                    creator of the User Content, or if you are acting on behalf of the
                                    creator, that you have express authority to submit or post the User
                                    Content on the Platform. You represent that submission and sharing of
                                    the User Content by you does not violate the rights of third parties
                                    including any intellectual property rights or rights of publicity or
                                    privacy.
                                </li>
                                <li>
                                    You shall not use the Platform in any way that causes, or may be
                                    likely to cause damage or impairment to the Platform or in any manner
                                    harms the Company or any other person or entity (as determined by the
                                    Company in its sole discretion) or interrupt free access to it in any
                                    way. You shall not use the Platform to host, display, upload, post,
                                    submit, distribute, modify, publish, transmit, update or share any
                                    User Content that:
                                    <ol style={{listStyleType: "lower-alpha"}}>
                                        <li>
                                            belongs to another person and to which you do not have any
                                            right;&nbsp;
                                        </li>
                                        <li>
                                            is grossly harmful, harassing, blasphemous defamatory obscene,
                                            pornographic, pedophilic, libelous, invasive of another's privacy,
                                            hateful, or racially, ethnically objectionable, disparaging,
                                            relating or encouraging money laundering or gambling, or otherwise
                                            unlawful in any manner whatever;&nbsp;
                                        </li>
                                        <li>harms minors in any way;&nbsp;</li>
                                        <li>
                                            infringes any patent, trademark, copyright or other proprietary
                                            rights;&nbsp;
                                        </li>
                                        <li>violates any law for the time being in force;&nbsp;</li>
                                        <li>
                                            deceives or misleads the addressee about the origin of such
                                            messages or communicates any information which is grossly
                                            offensive or menacing in nature;&nbsp;
                                        </li>
                                        <li>impersonates another person;&nbsp;</li>
                                        <li>
                                            contains software viruses or any other computer code, files or
                                            programs designed to interrupt, destroy or limit the functionality
                                            of the Platform and/or the Services or any connected network or
                                            any computer resource, or otherwise interferes with any entity’s
                                            use or enjoyment of the Platform and/or the Services; and&nbsp;
                                        </li>
                                        <li>
                                            threatens the unity, integrity, defence, security or sovereignty
                                            of India, friendly relations with foreign states, or public order
                                            or causes incitement to the commission of any cognizable offence
                                            or prevents investigation of any offence or is insults any other
                                            nation;
                                        </li>
                                        <li>is posted for any fraudulent purpose.</li>
                                    </ol>
                                </li>
                                <li>
                                    We have the right to review, use, edit, monitor, remove or modify any
                                    User Content posted by You. Subject to applicable laws, we have the
                                    right to disclose or share the User Content with third parties to
                                    ensure compliance with this Agreement, business purposes or compliance
                                    with applicable laws. You agree that we are not liable for the use or
                                    disclosure of any Personal Information or any other information that
                                    you disclose in connection with the User Content. Any User Content
                                    posted is the opinion or material of the person posting it and we are
                                    not responsible for any User Content posted or any damage caused to
                                    you based on your reliance on such User Content.
                                </li>
                                <li>
                                    You shall retain all ownership rights in and to the User Content you
                                    submit or post. However, by contributing User Content or other
                                    information on or through the Platform, you grant us a royalty-free,
                                    perpetual, irrevocable, sublicensable, fully paid-up, non-exclusive,
                                    transferrable, worldwide right and license to use, reproduce, create
                                    derivative works from, publish, edit, translate, distribute, perform,
                                    display, transmit the User Content alone or as part of other works in
                                    any form, media or technology, whether now known or hereafter
                                    developed, and to sublicense such rights through multiple tiers of
                                    sublicensees and without compensation to you. You waive any moral
                                    rights or other rights with respect to attribution of authorship or
                                    integrity of materials regarding the User Content that you may have
                                    under any applicable law under any legal theory. The license granted
                                    to us in any User Content or personal information submitted includes
                                    use for promotions, advertising, marketing, market research, feedback,
                                    quality control or any other lawful purpose.
                                </li>
                                <li>
                                    You will not engage in any form of antisocial, disrupting, or
                                    destructive acts, including "flaming," "spamming," "flooding,"
                                    "trolling," "phishing" and "griefing" as those terms are commonly
                                    understood and used on the internet.
                                </li>
                                <li>
                                    You will not delete or modify any content on the Platform and/or
                                    Services including but not limited to, legal notices, disclaimers or
                                    proprietary notices such as copyright or trademark symbols, logos,
                                    that you do not own or have express permission to modify.
                                </li>
                                <li>
                                    You agree that you shall:
                                    <ol>
                                        <li>
                                            ensure that all information provided by You on registration is
                                            accurate, correct and true and you will promptly inform us of any
                                            changes to such information by updating the details in your
                                            account;
                                        </li>
                                        <li>
                                            not attempt to circumvent our security or network including
                                            accessing data not intended for You;
                                        </li>
                                        <li>
                                            not commit any action which impairs the efficiency, functionality
                                            or operation of the Platform;
                                        </li>
                                        <li>
                                            not send unsolicited mail messages to individuals who did not
                                            specifically request for such material.
                                        </li>
                                        <li>
                                            Use the Services or the Platform in breach of this Agreement;
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Platform For Transactions and Communication</strong>
                            <ol>
                                <li>
                                    The Platform is a platform that users utilize to avail the Services.
                                    The Company is not and cannot be a party to or control in
                                    any&nbsp;manner any transaction between the platform’s users and the
                                    drivers.
                                </li>
                                <li>
                                    The Company does not make any representation or warranty as to
                                    specifics (such&nbsp;as quality, value, salability, etc.) of Services
                                    proposed to be offered through the Platform. Further, the Company does
                                    not give any warranty that the Services or the Platform are free from
                                    viruses or any defect which may adversely effect any technology. The
                                    Company does not implicitly or&nbsp;explicitly support or endorse the
                                    Services through&nbsp;the Platform. The Company accepts no liability
                                    for any errors or omissions, whether on&nbsp;behalf of itself or third
                                    parties.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Cancellation</strong>
                            <ol>
                                <li>
                                    The trips booked through Drop Taxi can be cancelled. There are no
                                    cancellation fees except in such cases where the Taxi has already
                                    arrived at customer’s location. In such cases Rs.300 as One time
                                    cancellation fee has to be paid to the driver.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Payment</strong>
                            <ol>
                                <li>
                                    Payment for the Services procured through the Platform shall be paid
                                    by you to Zero Drop Taxi in the following ways. For the avoidance of doubt,
                                    the Company does not provide Services directly to you and is solely
                                    the provider of a platform for facilitating transactions between you
                                    and various service providers.
                                </li>
                                <li>
                                    Presently, the Company offers only one payment option i.e. cash to be
                                    paid to the driver upon completion of the trip. We reserve the right
                                    to amend or update the payment mechanism for our Services.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Offers</strong>
                            <ol>
                                <li>
                                    The Company may, from time to time, provide offers on the Services
                                    listed on the Platform. An offer may be subject to certain terms and
                                    conditions accompanying an offer and availing of an offer by you shall
                                    constitute your agreement to the terms and conditions therein.
                                </li>
                                <li>
                                    The Company reserves the right to modify or cancel the terms of an
                                    offer without giving prior notice to you.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Vouchers</strong>
                            <ol>
                                <li>
                                    Vouchers are instruments in electronic or physical form which entitle
                                    the holder to receive the Services during the term stated on the
                                    Voucher. The Company may, from time to time, offer Vouchers for
                                    purchase or redemption by you.
                                </li>
                                <li>
                                    The Company reserves the right to cancel a purchased and unused
                                    Voucher if it reasonably suspects that you have committed or that you
                                    may be committing any fraud against the Company or its affiliates.
                                </li>
                                <li>
                                    Unless otherwise expressly stated:
                                    <ol>
                                        <li>The Voucher can be redeemed only once; and</li>
                                        <li>
                                            You must follow the redemption instructions associated with the
                                            Voucher when you redeem the Voucheron the Platform.
                                        </li>
                                        <li>
                                            If you decide to redeem the Voucher for services other than the
                                            Services mentioned on the Voucher, you will not be entitled to a
                                            credit, cash refund or a new Voucher for the difference between
                                            the value of the Services mentioned on the Voucher and the actual
                                            value of the services supplied and/or provided to you.
                                        </li>
                                        <li>
                                            Vouchers are redeemable in their entirety only. If, for whatever
                                            reason, you redeem the Voucherfor less than the original
                                            (un-discounted) value of the Voucher, you are not entitled to a
                                            credit, cash refund or new Voucher for the difference between the
                                            original (un-discounted) value and the redeemed value.
                                        </li>
                                        <li>
                                            In the event that Services cannot be provided as described in the
                                            Voucher for unforeseen reasons, we will notify you as soon as
                                            possible by email. We will offer you either a new Voucher with
                                            comparable benefits (if available) or the repayment of the
                                            purchase price of the Voucher.
                                        </li>
                                        <li>
                                            Any purchase of a Voucher is for your personal use only. The
                                            commercial trade of a Voucher is prohibited. The reproduction of a
                                            Voucher is prohibited.
                                        </li>
                                        <li>
                                            Your Voucher is solely your responsibility. We are not responsible
                                            for lost or stolen Voucheror for retrieving Voucher reference
                                            numbers or Voucher security codes.
                                        </li>
                                        <li>
                                            You promise not to provide false data including false names,
                                            addresses and/or contact or payment details; or engage in any
                                            unlawful activity in connection with the purchase or use of a
                                            Voucher, or allow anyone else to do so.
                                        </li>
                                        <li>
                                            Any attempt to redeem a Voucher contrary to these Terms may render
                                            a Voucher void at the discretion of the Company.
                                        </li>
                                        <li>
                                            If you redeem your Voucher, but the Services provided are
                                            deficient, or if you have a complaint regarding the provision of
                                            the Services, you must take action against the driver directly.
                                        </li>
                                        <li>
                                            Unless you expressly inform the Company in advance not to, any
                                            refund will be refunded to you via your original method of
                                            payment. If your original method of payment has been cancelled,
                                            expired or has otherwise changed, you must inform the customer
                                            support team immediately at +91 73583 80026  or through email
                                            ZeroDropTaxi@gmail.com . If you fail to do this and you are refunded
                                            to your original method of payment, you may need to coordinate
                                            with your bank or your payment service provider to obtain your
                                            refund. The Company not provide more than one refund.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Copyright And Database Rights</strong>
                            <ol>
                                <li>
                                    The Platform and all content included on the Platform, such as
                                    photographs, text, graphics, logos, button icons, images and software,
                                    is the exclusive property of the Company, its affiliates or its
                                    content suppliers.
                                </li>
                                <li>
                                    The compilation of all content on this Platform is the exclusive
                                    property of the Company.
                                </li>
                                <li>
                                    All software used on this Platform is the property of the Company or
                                    its software suppliers. You shall not systematically extract and/or
                                    re-utilize parts of the contents of the Platform without the Company’s
                                    express written consent. In particular, you shall not utilize any data
                                    mining, robots, or similar data gathering and extraction tools to
                                    extract (either once or a number of times) for re-utilization of any
                                    substantial parts of this Platform, without the Company’s prior and
                                    express written consent.
                                </li>
                                <li>
                                    You shall not create and/or publish your own database that features
                                    substantial parts of this platform and/or Platform without the
                                    Company’s express written consent. Your personal and non-commercial
                                    use of this Platform shall be subjected to the following restriction
                                    (i) you may not modify any content of the Platform, including but not
                                    limited to, any public display, description, performance, sale,
                                    rental, pricing of the Service; (ii) you may not decompile, reverse
                                    engineer, or disassemble the content, or (iii) remove any copyright,
                                    trademark registration, or other proprietary notices from the content.
                                    You further agree not to access or use this Platform in any manner
                                    that may be harmful to the operation of this Platform or its content.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Trademarks</strong>
                            <ol>
                                <li>
                                    Zero Drop Taxi and other marks indicated on the Platform are trademarks or
                                    registered trademarks of the Company. All other trademarks not owned
                                    by the Company that appear on this Platform are the property of their
                                    respective owners, who may or may not be affiliated with, connected
                                    to, or sponsored by the Company. We will not be responsible for any
                                    claims raised or any losses arising out of third party usage of any
                                    intellectual property belonging to you.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Communications</strong>
                            <ol>
                                <li>
                                    Using the Platform or sending emails to the Company constitutes
                                    electronic communication by you with the Company. The Company
                                    communicates with you by email or by posting notices on the Platform.
                                    For contractual purposes, you consent to receive communications from
                                    the Company electronically and agree that all agreements, notices,
                                    disclosures and other communications that are provided to you
                                    electronically satisfy any legal requirement stipulating that those
                                    communications be in writing. This condition does not affect your
                                    statutory rights.
                                </li>
                                <li>
                                    You understand that once you register on the Platform, you will
                                    receive short message service (“<strong>SMS</strong>”) messages from
                                    the Company on your registered mobile number. These messages could
                                    relate to your registration, transactions that you carry out through
                                    the Platform or any updates and promotions that are undertaken by the
                                    Company. Please note that the Company will send these SMS messages
                                    only to the registered mobile number or such other number that you may
                                    designate for any particular purpose.
                                </li>
                                <li>
                                    Further, the Company may also send notifications and reminders to you
                                    with respect to your activity on the Platform or in relation to
                                    theServices. Please note that while the Company endeavors to provide
                                    these notifications and reminders to you promptly, the Company does
                                    not provide any guarantee and shall not be held liable or responsible
                                    for any failure to send such notifications or reminders to you.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Termination</strong>
                            <ol>
                                <li>
                                    In the event that you breach these Terms of Use or in the event that
                                    any other user or a third-party reports violation of any of its rights
                                    as a result of your use of the Services, the Company reserves the
                                    right, to suspend or terminate such your access to the Platform with
                                    or without notice to you, and to exercise any other remedy available
                                    under the law.
                                </li>
                                <li>
                                    The Company shall have the right to indefinitely suspend or block
                                    access to your membership on the Platform and/or refuse to provide you
                                    access to the Platform if:
                                    <ol>
                                        <li>
                                            you engage in or are suspected of engaging in any illegal,
                                            fraudulent, misconduct or abusive activity; or
                                        </li>
                                        <li>
                                            you provide any information that is untrue, inaccurate, not
                                            current or incomplete in any respect or we have reasonable grounds
                                            to suspect that such information is untrue, inaccurate, not
                                            current or incomplete, or not in accordance with the Terms of Use.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Upon suspension or termination, your right to avail the Services
                                    through the Platform shall immediately cease and the Company reserves
                                    the right to remove or delete information regarding you that is
                                    available with the Company, including but not limited to login,
                                    account information and information posted by you.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Amendments</strong>
                            <ol>
                                <li>
                                    We reserve the right to make changes to our Platform, policies, and
                                    these Terms of Use at any time. You will be subject to the Terms of
                                    Use in force at the time that you use the Platform or at the time you
                                    place orders through our Platform, unless any change to those policies
                                    or these conditions is required to be made by law or government
                                    authority (in which case it will apply to orders previously placed by
                                    you). If any of these conditions are deemed invalid, void, or for any
                                    reason unenforceable, such condition will be deemed severable and will
                                    not affect the validity and enforceability of any remaining condition.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Disclaimers</strong>
                            <ol>
                                <li>
                                    The information, content and materials on this Platform and / or the
                                    Services are provided on an "as is" and "as available" basis. The
                                    Company and all its subsidiaries, affiliates, officers, employees,
                                    agents, partners and licensors disclaim all warranties of any kind,
                                    either express or implied, including but not limited to, implied
                                    warranties on merchantability, fitness for a particular purpose and
                                    non-infringement.
                                </li>
                                <li>
                                    The Company does not warrant that the functions contained in content,
                                    information and materials on the Platform and/or Services, including,
                                    without limitation any third-party sites or services linked to the
                                    Platform and/or Services will be uninterrupted, timely or error-free,
                                    that the defects will be rectified, or that the Platform or the
                                    servers that make such content, information and materials available
                                    are free of viruses or other harmful components.
                                </li>
                                <li>
                                    Any material downloaded or otherwise obtained through the Platform
                                    and/or Services are accessed at your own risk, and you will be solely
                                    responsible for any damage or loss of data that results from such
                                    download to your electronic device.
                                </li>
                                <li>
                                    You acknowledge that when you access a link that navigates you to
                                    other sites or Platforms, the site or Platform you will enter into is
                                    not controlled by the Company and different terms of use and privacy
                                    policy may apply. By accessing links to other sites or Platforms, you
                                    acknowledge that the Company is not responsible for those sites or
                                    Platforms and the Company shall not be liable for any damage caused
                                    due to usage of such sites. The Company reserves the right to disable
                                    links from third-party sites or Platforms to the Platform, although
                                    the Company is under no obligation to do so.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Indemnity And Limitation Of Liability</strong>
                            <ol>
                                <li>
                                    You hereby indemnify, defend, and hold the Company, the Company's
                                    affiliates, distributors, agents, representatives and other authorized
                                    users, and each of the foregoing entities' respective resellers,
                                    distributors, service providers and suppliers, and all of the
                                    foregoing entities' respective officers, directors, owners, employees,
                                    agents, representatives, harmless from and against any and all losses,
                                    damages, liabilities and costs arising from (i) your use of the
                                    Platform or the Services and/or violation of the Agreement by you;
                                    (ii) breach of applicable laws; (iii)
                                </li>
                                <li>
                                    You expressly understand that under no circumstances, including, but
                                    not limited to, negligence, shall the Company be liable to you or any
                                    other person or entity for any direct, indirect, incidental, special,
                                    or consequential damages, including, but not limited to damages for
                                    loss of profits, goodwill, use, data or other intangible losses,
                                    resulting from circumstances, including but not limited to: (i) the
                                    use or the inability to use the Platform and/or Services; or (ii) the
                                    cost of procurement of substitute services resulting from any services
                                    purchased or obtained or messages received or transactions entered
                                    into through or from the Platform and/or Services or (iii)
                                    unauthorized access to or alteration of your transmissions or data;
                                    (iv) statements or conduct of any third party on the Platform; or (v)
                                    any other matter relating to the Platform and/or Services.
                                </li>
                                <li>
                                    We shall neither be liable nor responsible for any actions or
                                    inactions of the other users of the Platform nor any breach of
                                    conditions, representations or warranties by them. We do not take any
                                    obligation to mediate or resolve any dispute or disagreement between
                                    you and the&nbsp;other users of the Platform.
                                </li>
                                <li>
                                    Further, none of the directors, officials or employees of the Company
                                    shall be personally liable for any action in connection with the
                                    Platform or the Services.
                                </li>
                                <li>
                                    We expressly disclaim all liabilities that may arise as a consequence
                                    of any unauthorized use of credit/ debit cards that have been used to
                                    make payments on the Platform.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Force Majeure</strong>
                            <ol>
                                <li>
                                    The Company will not be responsible in the event of failure of
                                    performance resulting directly or indirectly from an act of force
                                    majeure or causes beyond the Company's reasonable control including,
                                    without limitation, acts of god, war, equipment and technical
                                    failures, electrical power failures or fluctuations, strikes, labour
                                    disputes, riots, civil disturbances, shortages of labour or materials,
                                    natural disasters, orders of domestic or foreign courts or tribunals,
                                    non-performance of third parties, or any reasons beyond the reasonable
                                    control of the Company. You further acknowledge and agree that the
                                    Company shall not be responsible or liable for (a) any incompatibility
                                    between the Platform and/or Services and any other website, Platform,
                                    service, software or hardware; or (b) any delays or failures that
                                    users may experience with any transmissions or transactions relating
                                    to the Platform in an accurate or timely manner.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Report Abuse</strong>
                            <ol>
                                <li>
                                    In the event you come across any abuse or violation of the Terms or if
                                    you become aware of any objectionable content on the Platform, please
                                    report to ZeroDropTaxi@gmail.com.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Privacy Policy </strong>
                            <ol>
                                <li>
                                    You confirm that you have read, fully understand and accept the
                                    Privacy Policy.
                                </li>
                            </ol>
                            <h5>
                                {" "}
                                WHAT DO WE DO WITH YOUR INFORMATION?
                                <br/>
                            </h5>
                            <p>
                                When you attach taxi with us, we collect the personal information you
                                give us such as your name, address and email address. When you browse
                                our website, we also automatically receive your computer’s internet
                                protocol (IP) address in order to provide us with information that helps
                                us learn about your browser and operating system.
                            </p>
                            <h5> CONSENT </h5>
                            How do you get my consent?
                            <br/>
                            When you provide us with personal information to complete a transaction,
                            verify your credit card, place an order, we imply that you consent to our
                            collecting it and using it for that specific reason only. How do I
                            withdraw my consent? <br/>
                            If after you opt-in, you change your mind, you may withdraw your consent
                            for us to contact you, for the continued collection, use or disclosure of
                            your information, at any time, by contacting us at zerodroptaxi@gmail.com or
                            mailing us at: No 286 2nd Floor GST Road, Chrompet, Chennai -600044.
                            <h5> DISCLOSURE </h5>
                            We may disclose your personal information if we are required by law to do
                            so or if you violate our Terms of Service.
                            <h5> PAYMENT </h5>
                            We use Razorpay for processing payments. We/Razorpay do not store your
                            card data on their servers. The data is encrypted through the Payment Card
                            Industry Data Security Standard (PCI-DSS) when processing payment. Your
                            purchase transaction data is only used as long as is necessary to complete
                            your purchase transaction. After that is complete, your purchase
                            transaction information is not saved. Our payment gateway adheres to the
                            standards set by PCI-DSS as managed by the PCI Security Standards Council,
                            which is a joint effort of brands like Visa, MasterCard, American Express
                            and Discover. PCI-DSS requirements help ensure the secure handling of
                            credit card information by our store and its service providers.
                            <h5> THIRD-PARTY SERVICES </h5>
                            <p>
                                In general, the third-party providers used by us will only collect, use
                                and disclose your information to the extent necessary to allow them to
                                perform the services they provide to us. However, certain third-party
                                service providers, such as payment gateways and other payment
                                transaction processors, have their own privacy policies in respect to
                                the information we are required to provide to them for your
                                purchase-related transactions. For these providers, we recommend that
                                you read their privacy policies so you can understand the manner in
                                which your personal information will be handled by these providers. In
                                particular, remember that certain providers may be located in or have
                                facilities that are located a different jurisdiction than either you or
                                us. So if you elect to proceed with a transaction that involves the
                                services of a third-party service provider, then your information may
                                become subject to the laws of the jurisdiction(s) in which that service
                                provider or its facilities are located. Once you leave our store’s
                                website or are redirected to a third-party website or application, you
                                are no longer governed by this Privacy Policy or our website’s Terms of
                                Service.{" "}
                            </p>
                            <h5> SECURITY </h5>
                            To protect your personal information, we take reasonable precautions and
                            follow industry best practices to make sure it is not inappropriately
                            lost, misused, accessed, disclosed, altered or destroyed.
                            <h5> AGE OF CONSENT </h5>
                            By using this site, you represent that you are at least the age of
                            majority in your state or province of residence, or that you are the age
                            of majority in your state or province of residence and you have given us
                            your consent to allow any of your minor dependents to use this site.
                            <h5> CHANGES TO THIS PRIVACY POLICY </h5>
                            We reserve the right to modify this privacy policy at any time, so please
                            review it frequently. Changes and clarifications will take effect
                            immediately upon their posting on the website. If we make material changes
                            to this policy, we will notify you here that it has been updated, so that
                            you are aware of what information we collect, how we use it, and under
                            what circumstances, if any, we use and/or disclose it.
                            <h5>QUESTIONS AND CONTACT INFORMATION </h5>
                            If you would like to: access, correct, amend or delete any personal
                            information we have about you, register a complaint, or simply want more
                            information contact our Privacy Compliance Officer zerodroptaxi@gmail.com.
                        </li>
                        <li>
                            <strong>Grievance Officer</strong>
                            <ol>
                                <li>
                                    If you have any grievance with respect to the Platform or the Service,
                                    including any discrepancies and grievances with respect to processing
                                    of information, you can contact our Grievance Officer at:Mr R
                                    Raghavan, Manager Operations. Phone No +91 73583 80026 email ;
                                    zerodroptaxi@gmail.com
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Waiver</strong>
                            <ol>
                                <li>
                                    No term of these Terms of Use shall be deemed waived and no breach
                                    excused, unless such waiver or consent is in writing and signed by the
                                    Company. Any consent by the Company to, or waiver of a breach by you,
                                    whether expressed or implied, shall not constitute consent to, waiver
                                    of, or excuse for any other different or subsequent breach.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>assignment</strong>
                            <ol>
                                <li>
                                    You shall not assign any of your rights or obligations under this
                                    Agreement without prior approval which we may deny without assigning a
                                    reason. We shall have the right to assign any of our rights and
                                    obligations under these Terms.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Governing Law And Jurisdiction</strong>
                            <ol>
                                <li>
                                    Any dispute, claim or controversy arising out of, or relating to the
                                    breach, termination, enforcement, interpretation or validity thereof,
                                    including the determination of the scope or applicability of these
                                    Terms of Use, or to your use of the Company’s Platform or the service
                                    or information to which it gives access, shall be determined by
                                    arbitration in Chennai before a sole arbitrator. Arbitration shall be
                                    conducted in accordance with the rules laid down by the Arbitration
                                    and Conciliation Act, 1996. The seat of such arbitration shall be
                                    Chennai. All proceedings of such arbitration, including, without
                                    limitation, any awards, shall be in the English language. The award
                                    shall be final and binding on the parties.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Severability</strong>
                            <ol>
                                <li>
                                    If any provision of these Terms of Use is held by a court of competent
                                    jurisdiction or arbitral tribunal to be unenforceable under applicable
                                    law, then such provision shall be excluded from these Terms of Use and
                                    the remainder of these Terms of Use shall be interpreted as if such
                                    provision were so excluded and shall be enforceable in accordance with
                                    its terms; provided however that, in such event these Terms of Use
                                    shall be interpreted so as to give effect, to the greatest extent
                                    consistent with and permitted by applicable law, to the meaning and
                                    intention of the excluded provision as determined by such court of
                                    competent jurisdiction or arbitral tribunal.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Complete Understanding</strong>
                            <ol>
                                <li>
                                    These Terms of Use contain the entire understanding between the
                                    parties, and there are no other written or oral understandings or
                                    promises between the parties with respect to the subject matter of
                                    these Terms of Use other than those contained or referenced in these
                                    Terms of Use.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <br/>
            <br/>
            <h1 style={{textAlign:'center', fontSize:'2em'}}>YOU HAVE READ THESE TERMS & CONDITIONS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE</h1>
            <Footer/>
        </div>

    );
}


export default Terms;
